export function getCookie(name) {
  var re = new RegExp(`${name }=([^;]+)`)
  var value = re.exec(document.cookie)
  return (value !== null) ? unescape(value[1]) : null
}

export function resetCookie(name) {
  var domain = location.hostname
  if (domain !== 'localhost')
    document.cookie = `${name }=null; domain=${ domain.replace('webapps.', '')}`
  else
    document.cookie = `${name }=null`
}

// TODO: Look in to using a RegExp on the the hostname instead of checking on an indexOf() here
function domainReset(domain) {
  var acceptedDomainList = ['www-stage.', 'www-dev.', 'webapps.', 'www.']
  var replacedDomain

  acceptedDomainList.forEach(accepted => {
    if (domain.indexOf(accepted) == 0)
      replacedDomain = domain.replace(accepted, '')
  })
  return replacedDomain
}

export function setCookie(name, value, expireDaysFromNow, secure = false) {
  var domain = document.location.hostname
  var date = new Date()
  date.setTime(date.getTime() + (expireDaysFromNow * 24 * 60 * 60 * 1000))
  var cookieExpires = date.toGMTString()
  let cookie

  if (domain !== 'localhost')
    cookie = `name=${value}; domain=${domainReset(domain)}; expires=${cookieExpires}`
  else
    cookie = `${name}=${value}; expires=${cookieExpires}`

  if (secure) cookie += '; Secure'

  document.cookie = cookie
}

export function expireCookie(name) {
  setCookie(name, '', -1)
}

export default {
  domainReset,
  expireCookie,
  getCookie,
  resetCookie,
  setCookie
}
